import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import LoginForm from './LoginForm';
import ImagePair from './ImagePair';
import { authenticateUser } from './authUtils';

interface LoginPageProps {
  onLogin: (token: string) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLogin }) => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLoginSubmit = async (username: string, password: string) => {
    const token = await authenticateUser(username, password);
    if (token) {
      onLogin(token); // Llama a onLogin cuando el login es exitoso
      navigate('/salones'); // Navega a la página principal después de iniciar sesión
    } else {
      setError('Cuenta o contraseña inválida');
    }
  };

  return (
    <div className="login-page">
      <div className="login-content">
      <div className="right-column">
          <LoginForm onSubmit={handleLoginSubmit} error={error} />
        </div>
        <div className="left-column">
          <ImagePair
            image1="/images/login/16.png"
            image2="/images/login/17.png"
            alt1="Corte de cabello"
            alt2="Brochas de maquillaje"
          />
        </div>

      </div>
    </div>
  );
};

export default LoginPage;
