import React, { useEffect, useState, ReactNode } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
} from '@mui/material';

import { ComisionInterface, UserAttributes } from '../../../../interfaces/models';

import './Venta.css';
//TODO: agregar confirmacion visual del ticket creado realiaza exitosamente


interface Ticket {
  user_id_sale_made: number;
  id_service_product: number;
  oper: string;
  descripcion: string;
  cantidad: number;
  precioUnitario: number;
  tipoComision: number;
  comisionActiva: boolean;
  costoComisionAPagar: number;
  cantidadEnvase: string;
}

interface VentaIzquierdoProps {
  tickets: Ticket[];
  updateTicketOper: (index: number, usuarioId: number) => void;
  usuarios: UserAttributes[];
  handleTipoComisionChange: (event: SelectChangeEvent<string>, index: number) => void;
  comisionActual: ComisionInterface[];
  handleCantidadChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleCantidadBlur: (event: React.FocusEvent<HTMLInputElement>, index: number) => void;
  calculateTotal: (cantidad: number, precioUnitario: number) => number;
  removeTicket: (indexToRemove: number) => void;
  calculateGrandTotal: (data: Ticket[]) => number;
  observaciones: string;
  editingValues: { [key: number]: string };
  setEditingValues: React.Dispatch<React.SetStateAction<{ [key: number]: string }>>;
}



export const VentaIzquierdo: React.FC<VentaIzquierdoProps> = ({
    tickets,
    updateTicketOper,
    usuarios,
    handleTipoComisionChange,
    comisionActual,
    handleCantidadChange,
    handleCantidadBlur,
    calculateTotal,
    removeTicket,     
    calculateGrandTotal,
    observaciones,
    editingValues,
    setEditingValues
}) => {
  

            {/* Lado Izquierdo: Sección de Ticket */}
            return (
              <Grid item xs={12} md={12}>
                <Box display="flex" justifyContent="center">
                  <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    style={{
                      color: `rgb(0, 0, 255)`,
                      fontWeight: 'bold', // Aplica negrilla al texto
                    }}
                  >
                    DESCRIPCION DE TICKET
                  </Typography>
                </Box>
            
                <Box display="flex" justifyContent="center">
                  <Typography variant="body2">{new Date().toLocaleString()}</Typography>
                </Box>
            
                {/* Ajuste en el contenedor de la tabla para que ocupe el 100% del ancho */}
                <TableContainer component={Paper} sx={{ width: '100%', marginTop: 2 }}>
                  <Table aria-label="ticket table">
                    <TableHead>
                      <TableRow>
                        {['OPER', 'COMISION', 'DESCRIPCION', 'CANTIDAD', 'PRE. U.', 'PRE. T.'].map((header, index) => (
                          <TableCell key={index}>
                            <Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                              {header}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
            
                    <TableBody>
                    {tickets.map((ticket, index) => {
                      console.log(`Renderizando ticket en índice: ${index}`, ticket); // Debug dinámico del índice
                      return (
                        <TableRow key={index}>
                          {/* Oper */}
                          <TableCell>
                            <Select
                              value={ticket.oper}
                              onChange={(e) => {
                                const usuarioId = parseInt(e.target.value, 10);
                                if (!isNaN(usuarioId)) {
                                  updateTicketOper(index, usuarioId);
                                }
                              }}
                              displayEmpty
                              sx={{ width: '100%' }} // Se asegura que el select ocupe todo el espacio
                            >
                              {usuarios.map((usuario) => (
                                <MenuItem key={usuario.idUser} value={usuario.idUser}>
                                  {usuario.firstName} {usuario.lastName}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>

                          {/* Comisión */}
                          <TableCell>
  {ticket.comisionActiva && (
    <Select
  value={
    ticket.tipoComision !== 0 // Verifica si hay una comisión seleccionada
      ? comisionActual.findIndex(
          (comision) =>
            comision.comision === ticket.costoComisionAPagar &&
            ((comision.tipo === "%" && ticket.tipoComision === 1) ||
              (comision.tipo === "Bs" && ticket.tipoComision === 2))
        ) !== -1
        ? comisionActual.findIndex(
            (comision) =>
              comision.comision === ticket.costoComisionAPagar &&
              ((comision.tipo === "%" && ticket.tipoComision === 1) ||
                (comision.tipo === "Bs" && ticket.tipoComision === 2))
          ).toString()
        : "NA"
      : "NA"
  }
  onChange={(e) => handleTipoComisionChange(e, index)}
  displayEmpty
  sx={{ width: "100%" }}
>
  <MenuItem value="NA">Seleccionar</MenuItem>
  {comisionActual.map((comision, comisionIndex) => (
    <MenuItem key={comisionIndex} value={comisionIndex.toString()}>
      {comision.comision} {comision.tipo}
    </MenuItem>
  ))}
</Select>





  )}
</TableCell>


                          {/* Descripción */}
                          <TableCell>{ticket.descripcion}</TableCell>

                          {/* Cantidad */}
                          <TableCell>
                            <TextField
                              type="number"
                              value={editingValues[index] !== undefined ? editingValues[index] : ticket.cantidad.toString()}
                              onChange={(e) => handleCantidadChange(e as React.ChangeEvent<HTMLInputElement>, index)}
                              onBlur={(e) => handleCantidadBlur(e as React.FocusEvent<HTMLInputElement>, index)}
                              InputProps={{
                                inputProps: { min: 1 },
                              }}
                              variant="outlined"
                              size="medium"
                              sx={{ width: '100%' }} // Asegura que el campo de texto ocupe todo el espacio disponible
                            />
                          </TableCell>

                          {/* Precio Unitario */}
                          <TableCell>{ticket.precioUnitario.toFixed(2)}</TableCell>

                          {/* Precio Total */}
                          <TableCell>{calculateTotal(ticket.cantidad, ticket.precioUnitario).toFixed(2)}</TableCell>

                          {/* Eliminar Ticket */}
                          <TableCell sx={{ padding: 0, minWidth: '50px' }}>
                            <Button onClick={() => removeTicket(index)} sx={{ minWidth: '10px', padding: 0 }}>
                              X
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    </TableBody>
                  </Table>
                </TableContainer>
            
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Total Bs. {calculateGrandTotal(tickets).toFixed(2)}
                </Typography>
                
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  Observaciones: {observaciones}
                </Typography>
              </Grid>
            );
            
};


/*import React from 'react';
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import TicketRow from './TicketRow';
import { Ticket, UserAttributes, ComisionInterface } from '../../../../interfaces/models';

interface VentaIzquierdoProps {
  tickets: Ticket[];
  updateTicketOper: (index: number, usuarioId: number) => void;
  usuarios: UserAttributes[];
  handleTipoComisionChange: (event: React.ChangeEvent<{ value: unknown }>, index: number) => void;
  comisionActual: ComisionInterface[];
  handleCantidadChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleCantidadBlur: (event: React.FocusEvent<HTMLInputElement>, index: number) => void;
  calculateTotal: (cantidad: number, precioUnitario: number) => number;
  removeTicket: (indexToRemove: number) => void;
  calculateGrandTotal: (data: Ticket[]) => number;
  observaciones: string;
}

export const VentaIzquierdo: React.FC<VentaIzquierdoProps> = ({
  tickets,
  usuarios,
  handleTipoComisionChange,
  comisionActual,
  handleCantidadChange,
  handleCantidadBlur,
  calculateTotal,
  removeTicket,
  calculateGrandTotal,
  observaciones,
}) => {
  return (
    <Grid item xs={12} md={12}>
      <Box display="flex" justifyContent="center">
        <Typography variant="h6" gutterBottom style={{ color: 'rgb(0, 0, 255)', fontWeight: 'bold' }}>
          DESCRIPCIÓN DE TICKET
        </Typography>
      </Box>

      <TableContainer component={Paper} sx={{ width: '100%', marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              {['OPER', 'COMISIÓN', 'DESCRIPCIÓN', 'CANTIDAD', 'PRE. U.', 'PRE. T.'].map((header, index) => (
                <TableCell key={index}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tickets.map((ticket, index) => (
              <TicketRow
                key={index}
                ticket={ticket}
                index={index}
                usuarios={usuarios}
                comisionActual={comisionActual}
                handleTipoComisionChange={handleTipoComisionChange}
                handleCantidadChange={handleCantidadChange}
                handleCantidadBlur={handleCantidadBlur}
                calculateTotal={calculateTotal}
                removeTicket={removeTicket}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" sx={{ marginTop: 2 }}>
        Total Bs. {calculateGrandTotal(tickets).toFixed(2)}
      </Typography>

      <Typography variant="body1" sx={{ marginTop: 2 }}>
        Observaciones: {observaciones}
      </Typography>
    </Grid>
  );
};
*/