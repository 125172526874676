import React, { useState } from 'react';
import './LoginForm.css';

interface LoginFormProps {
  onSubmit: (username: string, password: string) => void;
  error: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, error }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(username, password); // Llama a onSubmit con los valores de usuario y contraseña
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className="login-form-container">
      <img src="/images/nova_blanco.png" alt="NOVA Logo" className="logo" />
      <h3 className="bienvenido-text">BIENVENIDO</h3>
      <hr className="divider" />
      <form onSubmit={handleSubmit} className="login-form">
        <div className="user-box">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            placeholder="Nombre de Usuario"
          />
        </div>
        <div className="user-box">
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Contraseña"
          />
          <span onClick={toggleShowPassword} className="password-toggle">
            {showPassword ? '🙈' : '👁️'}
          </span>
        </div>

        {error && <p className="error">{error}</p>}

        <div className="buttons">
          <button type="submit" className="ingreso-button">INGRESO</button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
