// theme.ts
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          margin: '8px',
          padding: '6px 12px',
        },
      },
    },
  },
  typography: {
    body1: {
      '@media (max-width:600px)': {
        padding: '0 8px',
      },
    },
  },
});

export default theme;
