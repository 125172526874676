import React, { useState } from 'react';
import { TextField, Grid, InputAdornment } from '@mui/material';

interface InputProps {
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  label: string;
  error?: boolean;
  helperText?: string;
  adornment?: string;
  borderColor?: string; // Nuevo prop para el color del borde
  backgroundColor?: string; // Nuevo prop para el color de fondo
}

export const TextInput: React.FC<InputProps> = ({
  value,
  onChange,
  onBlur,
  label,
  error,
  helperText,
  adornment,
  borderColor = 'black', // Color por defecto
  backgroundColor = 'white', // Color de fondo por defecto
}) => (
  <Grid item xs={12}>
    <TextField
      fullWidth
      label={label}
      variant="outlined"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        endAdornment: adornment ? (
          <InputAdornment position="end" style={{ color: 'gray' }}>
            {adornment}
          </InputAdornment>
        ) : null,
        style: { border: `2px solid ${borderColor}` }, // Aplicar color del borde
      }}
      InputLabelProps={{
        style: {
          backgroundColor, // Fondo definido
          padding: '0 4px',
        },
      }}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Hacer la línea de borde transparente
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Mantener transparente al hover
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Mantener transparente cuando está enfocado
        },
        '& .MuiInputLabel-root': {
          transform: 'translate(14px, 12px) scale(1)', // Posición inicial
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)', // Posición cuando está activo
            zIndex: 1,
          },
        },
      }}
      error={error}
      helperText={helperText}
    />
  </Grid>
);

interface NumberInputProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  adornment?: string;
  error?: boolean;
  helperText?: string;
  borderColor?: string; // Nuevo prop para el color del borde
  backgroundColor?: string; // Nuevo prop para el color de fondo
}

export const NumberInput: React.FC<NumberInputProps> = ({
  value,
  setValue,
  label,
  adornment = 'Bs',
  error = false,
  helperText = '',
  borderColor,
  backgroundColor,
}) => {
  const [localError, setLocalError] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    setLocalError(false); // Resetea el error mientras se escribe
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue === '' || isNaN(Number(newValue))) {
      setLocalError(true);
    } else {
      setLocalError(false);
    }
  };

  return (
    <TextInput
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      label={label}
      error={localError || error}
      helperText={localError ? 'Este campo solo admite números' : helperText}
      adornment={adornment}
      borderColor={borderColor} // Pasar color del borde
      backgroundColor={backgroundColor} // Pasar color de fondo
    />
  );
};

interface MoneyInputProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  adornment?: string;
  error?: boolean;
  helperText?: string;
  borderColor?: string;
  backgroundColor?: string;
}

export const MoneyInput: React.FC<MoneyInputProps> = ({
  value,
  setValue,
  label,
  adornment = 'Bs',
  error = false,
  helperText = '',
  borderColor,
  backgroundColor,
}) => {
  const [localError, setLocalError] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    setLocalError(false); // Resetea el error mientras se escribe
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/,/g, '.'); // Convierte comas a puntos
    if (newValue === '' || isNaN(Number(newValue))) {
      setLocalError(true);
    } else {
      setValue(parseFloat(newValue).toFixed(2)); // Formatear a 2 decimales
      setLocalError(false);
    }
  };

  return (
    <TextInput
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      label={label}
      error={localError || error}
      helperText={localError ? 'Este campo solo admite dinero' : helperText}
      adornment={adornment}
      borderColor={borderColor} // Pasar color del borde
      backgroundColor={backgroundColor} // Pasar color de fondo
    />
  );
};


interface TextInputWithFormatProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  capitalize?: boolean;
  error?: boolean;
  helperText?: string;
  borderColor?: string;
  backgroundColor?: string;
}

export const TextInputWithFormat: React.FC<TextInputWithFormatProps> = ({
  value,
  setValue,
  label,
  capitalize = false,
  error = false,
  helperText = '',
  borderColor,
  backgroundColor,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (capitalize) {
      const capitalizedValue = newValue
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      setValue(capitalizedValue);
    }
  };

  return (
    <TextInput
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      label={label}
      error={error}
      helperText={helperText}
      borderColor={borderColor} // Pasar color del borde
      backgroundColor={backgroundColor} // Pasar color de fondo
    />
  );
};


interface DateInputProps {
  value: string; // Ahora siempre será una cadena (nunca null)
  setValue: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  error?: boolean;
  helperText?: string;
  borderColor?: string;
  backgroundColor?: string;
}

export const DateInput: React.FC<DateInputProps> = ({
  value,
  setValue,
  label,
  error = false,
  helperText = '',
  borderColor = 'black',
  backgroundColor = 'white',
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue); // Solo asigna la cadena (nunca null)
  };

  return (
    <Grid item xs={12}>
      <TextField
        fullWidth
        label={label}
        type="date" // Configuración para tipo de entrada de fecha
        variant="outlined"
        value={value} // Siempre será una cadena (vacía o con contenido)
        onChange={handleChange}
        InputLabelProps={{
          shrink: true, // Para que la etiqueta no se superponga al valor
        }}
        error={error}
        helperText={helperText}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor, // Fondo definido
            border: `2px solid ${borderColor}`, // Borde personalizado
          },
        }}
      />
    </Grid>
  );
};
