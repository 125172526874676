import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import AgregarOperario from './AgregarOperario/AgregarOperario';
import AgregarProducto from './AgregarPS/AgregarProducto';
import SueldosOperario from './Sueldos/SueldosOperario';
import OpcionesGrid from '../../../general/OpcionesGrid';
import CornerButtons from '../../../utils/CornerButtons';

const Administracion: React.FC = () => {
  const [seccionActiva, setSeccionActiva] = useState<string | null>(null);
  const [enVistaSeleccion, setEnVistaSeleccion] = useState(true);

  const handleSeleccionarSeccion = (seccion: string) => {
    setSeccionActiva(seccion);
    setEnVistaSeleccion(false);
  };

  const handleVolverASeleccion = () => {
    setSeccionActiva(null);
    setEnVistaSeleccion(true);
  };

  const renderizarSeccion = () => {
    switch (seccionActiva) {
      case 'agregarOperario':
        return <AgregarOperario />;
      case 'agregarpc':
        return <AgregarProducto />;
      case 'sueldos':
        return <SueldosOperario />;
      default:
        return null;
    }
  };

  const opciones = [
    {
      titulo: 'Personal',
      imagen: '/images/opciones/25.png',
      onClick: () => handleSeleccionarSeccion('agregarOperario'),
    },
    {
      titulo: 'GESTIÓN P/S',
      imagen: '/images/opciones/26.png',
      onClick: () => handleSeleccionarSeccion('agregarpc'),
    },
    {
      titulo: 'Sueldos',
      imagen: '/images/opciones/27.png',
      onClick: () => handleSeleccionarSeccion('sueldos'),
    },
  ];

  return (
    <Box className="gestion-container" sx={{ marginTop: 0, padding: 0 }}>
      {enVistaSeleccion ? (
        // Vista inicial usando OpcionesGrid con un array de opciones
        <>
          <OpcionesGrid opciones={opciones} />
          <CornerButtons
            leftButton={{
              to: '/salones', // Ruta para el botón izquierdo en la vista inicial
              icon: '/images/iconos/flecha_atras_negro.png', // Ícono del botón izquierdo
              altText: 'Back', // Texto alternativo para el ícono izquierdo
            }}
            rightButton={{
              to: '/salones', // Ruta para el botón derecho en la vista inicial
              icon: '/images/iconos/home_back.png', // Ícono del botón derecho
              altText: 'Home', // Texto alternativo para el ícono derecho
            }}
          />
        </>
      ) : (
        // Renderizado de la sección activa
        <Box>
          {renderizarSeccion()}
          {/* Botón de volver para la sección activa */}
          <CornerButtons
            leftButton={{
              onClick: handleVolverASeleccion, // Ruta para el botón izquierdo (puedes ajustarla)
              icon: '/images/iconos/flecha_atras_negro.png', // Ícono del botón izquierdo
              altText: 'Back', // Texto alternativo para el ícono izquierdo
            }}
            rightButton={{
              to: '/salones', // Ruta para el botón derecho
              icon: '/images/iconos/home_back.png', // Ícono del botón derecho
              altText: 'Home', // Texto alternativo para el ícono derecho
            }}
          />
        </Box>
      )}
  
      {/* Botones de navegación en la vista inicial */}

    </Box>
  );
  
};

export default Administracion;
