import React, { useContext, useEffect, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Container, Snackbar, Alert } from '@mui/material';

import { CalendarEvent } from './types';
import InventoryContext from '../../../../contexts/InventoryContext';
import ReservationDialog from './ReservationDialog';
import { ClientAttributesInterface, ReservationAttributesInterface } from '../../../../interfaces/models';


moment.locale('es');
const localizer = momentLocalizer(moment);

const Reservations: React.FC = () => {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error('Reservations debe usarse dentro de un InventoryProvider');
  }

  const { tableData, addItem, updateItem, deleteItem } = context;

  const [reservations, setReservations] = useState<CalendarEvent[]>([]);
  const [clients, setClients] = useState<ClientAttributesInterface[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({ open: false, message: '', severity: 'success' });

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbar({ open: true, message, severity });
  };

  const fetchReservations = () => {
    const response = tableData.reservations;
    if (Array.isArray(response)) {
      const reservasMapeadas = response.map((reserva: ReservationAttributesInterface) => ({
        id: reserva.idReserva ?? 0,
        title: reserva.notas || 'Reserva',
        start: new Date(reserva.fecha!),
        end: new Date(new Date(reserva.fecha!).getTime() + 60 * 60 * 1000), // 1 hora de duración
        idCliente: reserva.idCliente,
      }));
      setReservations(reservasMapeadas);
    } else {
      setReservations([]);
    }
  };

  const fetchClients = () => {
    const response = tableData.clients;
    if (Array.isArray(response)) {
      setClients(response);
    } else {
      setClients([]);
    }
  };

  const handleSelectSlot = (slotInfo: { start: Date }) => {
    setSelectedEvent(null);
    setOpenDialog(true);
  };

  const handleSelectEvent = (event: CalendarEvent) => {
    setSelectedEvent(event);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setSelectedEvent(null);
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchReservations();
    fetchClients();
  }, [tableData]);

  return (
    <Container>
      <BigCalendar
        localizer={localizer}
        events={reservations}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600, margin: '20px 0' }}
        selectable
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        views={['month', 'week', 'day', 'agenda']}
        messages={{
          month: 'Mes',
          day: 'Día',
          week: 'Semana',
          today: 'Hoy',
          previous: 'Anterior',
          next: 'Siguiente',
          agenda: 'Agenda', // Traducción del nombre de la vista "Agenda"
          noEventsInRange: 'No hay eventos en este rango', // Mensaje cuando no hay eventos
          date: 'Fecha',
          time: 'Hora',
          event: 'Evento',
        }}
        defaultDate={new Date()}
      />


      <ReservationDialog
        open={openDialog}
        onClose={handleDialogClose}
        clients={clients}
        selectedEvent={selectedEvent}
        addItem={addItem}
        updateItem={updateItem}
        deleteItem={deleteItem}
        fetchReservations={fetchReservations}
        showSnackbar={showSnackbar}
      />

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

export default Reservations;
