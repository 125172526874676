import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { NumberInput, MoneyInput, TextInputWithFormat, DateInput } from './InputComponents';

interface InputProps {
  type?: 'number' | 'money' | 'text' | 'date';
  label: string;
  value: string; // Aceptar valores nulos
  setValue: React.Dispatch<React.SetStateAction<string>>;
  capitalize?: boolean;
  adornment?: string;
  borderColor?: string;
  backgroundColor?: string;
  error?: boolean;
  helperText?: string;
}

interface InputTableProps {
  title?: string;
  rows: Array<Array<InputProps>>;
  onButtonClick: (data: Array<Array<{ label: string; value: string }>>) => void;
  buttonStyle?: React.CSSProperties;
  buttonText?: string; // Nuevo prop opcional para el texto del botón
}

const InputTable: React.FC<InputTableProps> = ({ title, rows, onButtonClick, buttonStyle, buttonText = "Aceptar" }) => {
  const handleButtonClick = () => {
    const data = rows.map(row =>
      row.map(input => ({
        label: input.label,
        value: input.value || '', // Asignar cadena vacía si el valor es nulo
      }))
    );
    onButtonClick(data);
  };


  const defaultButtonStyle: React.CSSProperties = {
    padding: '10px 24px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#D8BFD8',
    color: '#4B0082',
    borderRadius: '12px',
    fontWeight: '500',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    textTransform: 'uppercase',
  };

  return (
    <Paper style={{ padding: '20px', margin: '20px', overflow: 'hidden' }}>
      {title && (
        <Typography variant="h5" align="center" gutterBottom style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
          {title}
        </Typography>
      )}
      <Grid container spacing={2}>
        {rows.map((row, rowIndex) => (
          <Grid container item xs={12} key={rowIndex} spacing={2}>
            {row.map((input, inputIndex) => {
              const commonProps = {
                label: input.label,
                value: input.value,
                setValue: input.setValue,
                borderColor: input.borderColor,
                backgroundColor: input.backgroundColor,
                error: input.error,
                helperText: input.helperText,
              };

              return (
                <Grid item xs={12} sm={12 / row.length} key={inputIndex}>
                  {input.type === 'number' && (
                    <NumberInput {...commonProps} adornment={input.adornment} />
                  )}
                  {input.type === 'money' && (
                    <MoneyInput {...commonProps} adornment={input.adornment} />
                  )}
                  {input.type === 'text' && (
                    <TextInputWithFormat {...commonProps} capitalize={input.capitalize} />
                  )}
                  {input.type === 'date' && <DateInput {...commonProps} />} {/* Caso para 'date' */}
                             
                </Grid>
              );
            })}
          </Grid>
        ))}
        <Grid item xs={12} style={{ marginTop: '20px', textAlign: 'center' }}>
          <button
            onClick={handleButtonClick}
            style={{ ...defaultButtonStyle, ...buttonStyle }} // Combina estilo por defecto y personalizado
          >
            {buttonText} {/* Muestra el texto del botón, usa "Aceptar" si no se especifica */}
          </button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default InputTable;
