import React, { useContext } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { NavLink, Outlet } from 'react-router-dom';  // Importa Outlet
import InventoryContext from '../contexts/InventoryContext';
import './MainLayoutAdmin.css';
import CornerButtons from '../utils/CornerButtons';

interface MainLayoutAdminProps {
  onLogout: () => void;
}

const MainLayoutAdmin: React.FC<MainLayoutAdminProps> = ({ onLogout }) => {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error('MainLayoutAdmin must be used within an InventoryProvider');
  }

  const { rol } = context; // Obtén el rol del usuario desde el contexto

  return (
    <Container maxWidth={false} disableGutters className="main-layout-container">
      {/* Top Row */}

      {/* Middle Row */}
      <Box className="middle-row">
        {rol === 1 && ( // Mostrar todas las opciones para el rol 1 (Administrador)
          <>
            <Button component={NavLink} to="/salones/reportes" className="icon-button">
              <img src="/images/iconos/reportes_5.png" alt="Reportes" className="icon-image" />
              <Typography className="icon-text">Reportes</Typography>
            </Button>
            <Button component={NavLink} to="/salones/administracion" className="icon-button">
              <img src="/images/iconos/administracion.png" alt="Administración" className="icon-image" />
              <Typography className="icon-text">Administración</Typography>
            </Button>
          </>
        )}
        {(rol === 1 || rol === 2) && ( // Mostrar opciones comunes a ambos roles
          <>
            <Button component={NavLink} to="/salones/atencion" className="icon-button">
              <img src="/images/iconos/caja.png" alt="Caja" className="icon-image" />
              <Typography className="icon-text">Caja</Typography>
            </Button>
            <Button component={NavLink} to="/salones/reservas" className="icon-button">
              <img src="/images/iconos/reservas.png" alt="Reservas" className="icon-image" />
              <Typography className="icon-text">Reservas</Typography>
            </Button>
          </>
        )}
      </Box>

      {/* Bottom Row */}
      <CornerButtons
        leftButton={{
          onClick: () => "", // Botón izquierdo (opcional)
          icon: '/images/iconos', // Ícono del botón izquierdo
          altText: '', // Texto alternativo
        }}
        rightButton={{
          onClick: onLogout, // Lógica de logout
          to: '/login', // Redirigir al login después de cerrar sesión
          icon: '/images/iconos/exit.png',
          altText: 'Salir',
        }}
      />

      {/* Aquí se renderizarán las subrutas de /admin */}
      <Outlet /> {/* Este es el lugar donde se renderizarán las subrutas */}
    </Container>
  );
};

export default MainLayoutAdmin;

