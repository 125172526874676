import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import Clientes from './Clientes';
import { NavLink } from 'react-router-dom';
import OpcionesGrid from '../../../general/OpcionesGrid';
import CornerButtons from '../../../utils/CornerButtons';
import ManejoRecordatorios from './ManejoRecordatorios';
import Reservations from './Reservas/Reservations';

const PaginaReservas: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null); // Estado para la sección seleccionada
  const [enVistaSeleccion, setEnVistaSeleccion] = useState(true); // Estado para la vista inicial

  const handleSeleccionarSeccion = (seccion: string) => {
    setActiveSection(seccion);
    setEnVistaSeleccion(false);
  };

  const handleVolverASeleccion = () => {
    setActiveSection(null);
    setEnVistaSeleccion(true);
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'clientes':
        return <Clientes />;
      case 'reservas':
        return <Reservations />;
      case 'recordatorios':
        return <ManejoRecordatorios onClose={handleVolverASeleccion} />; // Nuevo caso
    
      default:
        return <></>;
    }
  };

  const opciones = [
    { titulo: 'REGISTRO DE CLIENTES', imagen: '/images/opciones/29.png', onClick: () => handleSeleccionarSeccion('clientes') },
    { titulo: 'Reservas', imagen: '/images/opciones/30.png', onClick: () => handleSeleccionarSeccion('reservas') },
    //{ titulo: 'Manejo de Recordatorios', imagen: '/images/opciones/30.png', onClick: () => handleSeleccionarSeccion('recordatorios') }, // Nueva opción
  ];
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {enVistaSeleccion ? (
        // Vista inicial usando OpcionesGrid con opciones de selección
        <Box sx={{ width: '100%', maxWidth: '80vw', flexGrow: 1 }}>
          <OpcionesGrid opciones={opciones} />

          {/* Botones de "Atrás" y "Home" en la vista inicial */}
          <CornerButtons
            leftButton={{
              to: '/salones',
              icon: '/images/iconos/flecha_atras_negro.png', // Ícono del botón izquierdo
              altText: 'Back', // Texto alternativo para el ícono izquierdo
            }}
            rightButton={{
              to: '/salones', // Ruta para el botón derecho
              icon: '/images/iconos/home_back.png', // Ícono del botón derecho
              altText: 'Home', // Texto alternativo para el ícono derecho
            }}
          />

        </Box>
      ) : (
        // Vista de la sección seleccionada con botón de "Atrás"
        <Box sx={{ padding: '0 16px', flexGrow: 1, width: '100%', maxWidth: '80vw' }}>
          {renderSection()}
          <CornerButtons
            leftButton={{
              onClick: handleVolverASeleccion, // Ruta para el botón izquierdo (puedes ajustarla)
              icon: '/images/iconos/flecha_atras_negro.png', // Ícono del botón izquierdo
              altText: 'Back', // Texto alternativo para el ícono izquierdo
            }}
            rightButton={{
              to: '/salones', // Ruta para el botón derecho
              icon: '/images/iconos/home_back.png', // Ícono del botón derecho
              altText: 'Home', // Texto alternativo para el ícono derecho
            }}
          />

        </Box>
      )}
    </Box>
  );
};

export default PaginaReservas;
