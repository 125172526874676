import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, MenuItem } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'; // Calendario visual
import moment from 'moment';
import { CalendarEvent } from './types';
import { ClientAttributesInterface, ReservationAttributesInterface } from '../../../../interfaces/models';

interface ReservationDialogProps {
  open: boolean;
  onClose: () => void;
  clients: ClientAttributesInterface[];
  selectedEvent: CalendarEvent | null;
  addItem: (table: string, item: any) => Promise<any>;
  updateItem: (table: string, id: number, item: any) => Promise<any>;
  deleteItem: (table: string, id: number) => Promise<any>;
  fetchReservations: () => void;
  showSnackbar: (message: string, severity: 'success' | 'error') => void;
}

const ReservationDialog: React.FC<ReservationDialogProps> = ({
  open,
  onClose,
  clients,
  selectedEvent,
  addItem,
  updateItem,
  deleteItem,
  fetchReservations,
  showSnackbar,
}) => {
  const [formFields, setFormFields] = useState({
    idCliente: '',
    notas: '',
    fecha: new Date(),
  });

  useEffect(() => {
    if (!open) return;
    if (selectedEvent) {
      setFormFields({
        idCliente: selectedEvent.idCliente ? selectedEvent.idCliente.toString() : '',
        notas: selectedEvent.title,
        fecha: selectedEvent.start,
      });
    } else {
      setFormFields({ idCliente: '', notas: '', fecha: new Date() });
    }
  }, [open, selectedEvent]);

  const handleSubmit = async () => {
    const newReservation: ReservationAttributesInterface = {
      idCliente: parseInt(formFields.idCliente, 10),
      fecha: formFields.fecha,
      notas: formFields.notas,
      idSalon: 0,
      idReserva: selectedEvent?.id
    };

    console.log(" --- selectedEvent.id: " , selectedEvent)
    console.log(" --- newReservation.id: " , newReservation)
    const response = selectedEvent
      ? await updateItem('reservations', selectedEvent.id, newReservation)
      : await addItem('reservations', newReservation);

    if (response.status) {
      fetchReservations();
      showSnackbar(`Reserva ${selectedEvent ? 'actualizada' : 'agregada'} exitosamente.`, 'success');
      onClose();
    } else {
      showSnackbar(`Error al ${selectedEvent ? 'actualizar' : 'agregar'} la reserva: ${response.answer}`, 'error');
    }
  };

  const handleDelete = async () => {
    if (selectedEvent) {
      const response = await deleteItem('reservations', selectedEvent.id);
      if (response.status) {
        fetchReservations();
        showSnackbar('Reserva eliminada exitosamente.', 'success');
        onClose();
      } else {
        showSnackbar(`Error al eliminar la reserva: ${response.answer}`, 'error');
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{selectedEvent ? 'Editar Reserva' : 'Nueva Reserva'}</DialogTitle>
      <DialogContent>
        <DateCalendar
          value={formFields.fecha}
          onChange={(date) => setFormFields({ ...formFields, fecha: date! })}
        />
<TextField
  select
  label="Hora de inicio"
  fullWidth
  margin="normal"
  value={moment(formFields.fecha).format('HH:mm')}
  onChange={(e) => {
    const [hours, minutes] = e.target.value.split(':');
    const updatedDate = new Date(formFields.fecha);
    updatedDate.setHours(parseInt(hours, 10));
    updatedDate.setMinutes(parseInt(minutes, 10));
    setFormFields({ ...formFields, fecha: updatedDate });
  }}
  SelectProps={{
    MenuProps: {
      PaperProps: {
        style: {
          maxHeight: 200, // Altura máxima del menú desplegable
          width: '120px', // Ancho más compacto
          overflowY: 'auto', // Permite desplazamiento
        },
      },
    },
  }}
>
  {Array.from({ length: 24 * 4 }, (_, index) => {
    const time = moment().startOf('day').add(index * 15, 'minutes').format('HH:mm');
    return (
      <MenuItem
        key={time}
        value={time}
        style={{
          fontSize: '0.9rem', // Letra más pequeña
          padding: '4px 8px', // Reduce padding
        }}
      >
        {time}
      </MenuItem>
    );
  })}
</TextField>

        <TextField
          label="Cliente"
          select
          fullWidth
          margin="normal"
          value={formFields.idCliente}
          onChange={(e) => setFormFields({ ...formFields, idCliente: e.target.value })}
        >
          {clients.map((client) => (
            <MenuItem key={client.idClient} value={client.idClient}>
              {client.nombre}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Descripcion"
          type="text"
          fullWidth
          margin="normal"
          value={formFields.notas}
          onChange={(e) => setFormFields({ ...formFields, notas: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        {selectedEvent && (
          <Button onClick={handleDelete} color="error">
            Eliminar
          </Button>
        )}
        <Button onClick={onClose} color="inherit">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {selectedEvent ? 'Actualizar' : 'Agregar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReservationDialog;
