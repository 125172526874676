import React from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

interface ManejoRecordatoriosProps {
  onClose: () => void; // Función para cerrar el popup
}

const ManejoRecordatorios: React.FC<ManejoRecordatoriosProps> = ({ onClose }) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>¡Próximamente!</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          La funcionalidad de <b>Manejo de Recordatorios</b> estará disponible pronto.
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          Podrás:
          <ul>
            <li>Enviar recordatorios automáticos a los clientes.</li>
            <li>Gestionar seguimientos personalizados.</li>
            <li>Y mucho más...</li>
          </ul>
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          Mira nuestro video para más información:
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ver Video
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Volver
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManejoRecordatorios;
