import React from 'react';
import { Box, Typography } from '@mui/material';
import OpcionCard from './OpcionCard';

interface Opcion {
  titulo: string;
  imagen: string;
  onClick: () => void;
  vistaAbajo?: boolean; // Nuevo parámetro opcional
}

interface OpcionesGridProps {
  opciones: Opcion[];
}

const OpcionesGrid: React.FC<OpcionesGridProps> = ({ opciones }) => {
  // Filtrar opciones normales y las que tienen "vistaAbajo"
  const opcionesNormales = opciones.filter(opcion => !opcion.vistaAbajo);
  const opcionesVistaAbajo = opciones.filter(opcion => opcion.vistaAbajo);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Para que los elementos estén en columna
        justifyContent: 'center', // Centra verticalmente
        alignItems: 'center', // Centra horizontalmente
        flexWrap: 'wrap', // Permite que los elementos se ajusten
        gap: 3, // Espaciado entre los elementos
        height: '100vh', // Ocupa toda la pantalla verticalmente
        padding: 2,
        boxSizing: 'border-box', // Asegura que el padding no afecte el tamaño total
      }}
    >
      {/* Opciones principales */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap', // Permite ajustar los elementos en varias filas
          gap: 3, // Espaciado entre tarjetas
          width: '100%', // Asegura que ocupe todo el ancho del contenedor
        }}
      >
        {opcionesNormales.map((opcion, index) => (
          <OpcionCard
            key={index}
            titulo={opcion.titulo}
            imagen={opcion.imagen}
            onClick={opcion.onClick}
          />
        ))}
      </Box>

      {/* Opciones con "vistaAbajo" justo debajo */}
      {opcionesVistaAbajo.map((opcion, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: 2,
          }}
        >
          <Box
            component="img"
            src={opcion.imagen}
            alt={opcion.titulo}
            onClick={opcion.onClick}
            sx={{
              cursor: 'pointer',
              width: '15vw',
              maxWidth: '120px',
              height: 'auto',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
          />
          <Typography
            onClick={opcion.onClick}
            sx={{
              marginTop: 1,
              fontSize: '1rem',
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            {opcion.titulo}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default OpcionesGrid;
