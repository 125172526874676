import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tab,
  Tabs,
  TablePagination,
} from '@mui/material';
import InventoryContext from '../../../../contexts/InventoryContext';
import { SaleAttributesInterface, ServiceProductAttributesInterface, UserAttributes } from '../../../../interfaces/models';

const ArqueoDiario: React.FC = () => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('ArqueoDiario must be used within an InventoryProvider');
  }

  const { tableData, idUsuario, usuarioActual } = context;

  const [ventasDelDiaSalon, setVentasDelDiaSalon] = useState<SaleAttributesInterface[]>([]);
  const [ventasDelDiaUsuario, setVentasDelDiaUsuario] = useState<SaleAttributesInterface[]>([]);
  const [productosServicios, setProductosServicios] = useState<ServiceProductAttributesInterface[]>([]);
  const [usuarios, setUsuarios] = useState<UserAttributes[]>([]);

  const [activeTab, setActiveTab] = useState(0); // Pestaña activa
  const [page, setPage] = useState(0); // Página actual
  const [rowsPerPage, setRowsPerPage] = useState(5); // Filas por página

  // Filtrar ventas del día
  const filtrarVentasDelDia = (ventas: SaleAttributesInterface[], userId?: number) => {
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0);

    return ventas.filter(venta => {
      const fechaVenta = new Date(venta.createdAt || '');
      fechaVenta.setHours(0, 0, 0, 0);
      const perteneceUsuario = userId ? venta.idUser === userId : true;
      return fechaVenta.getTime() === hoy.getTime() && perteneceUsuario;
    });
  };

  // Obtener datos relacionados (productos/servicios y usuarios)
  const obtenerNombreProductoServicio = (id: number) =>
    productosServicios.find(producto => producto.idServiceProduct === id)?.detalles || 'Desconocido';

  const obtenerNombreUsuario = (id: number) =>
    usuarios.find(usuario => usuario.idUser === id)?.firstName || 'Desconocido';

  useEffect(() => {
    if (tableData.sales) {
      setProductosServicios(tableData.serviceProducts || []);
      setUsuarios(tableData.usuarios || []);

      // Ventas de todo el salón
      setVentasDelDiaSalon(filtrarVentasDelDia(tableData.sales));

      // Ventas del usuario actual
      setVentasDelDiaUsuario(filtrarVentasDelDia(tableData.sales, idUsuario!));
    }
  }, [tableData, idUsuario]);

  // Cambiar de pestaña
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setPage(0); // Reiniciar la paginación al cambiar de pestaña
  };

  // Cambiar de página
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Cambiar filas por página
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTablaVentas = (ventas: SaleAttributesInterface[]) => (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID Venta</TableCell>
              <TableCell>Usuario</TableCell>
              <TableCell>Producto/Servicio</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Fecha</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ventas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((venta, index) => (
              <TableRow key={index}>
                <TableCell>{venta.idSales}</TableCell>
                <TableCell>{obtenerNombreUsuario(venta.idUser || 0)}</TableCell>
                <TableCell>{obtenerNombreProductoServicio(venta.idServiceProduct || 0)}</TableCell>
                <TableCell>{venta.quantity}</TableCell>
                <TableCell>{venta.cantidadPago?.toFixed(2)} Bs</TableCell>
                <TableCell>{new Date(venta.createdAt || '').toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={ventas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Arqueo Diario
      </Typography>
      <Tabs value={activeTab} onChange={handleChangeTab} centered>
        <Tab label="Ventas del Salón" />
        <Tab label="Mis Ventas" />
      </Tabs>
      <Box sx={{ marginTop: 2 }}>
        {activeTab === 0 && (
          <Box>
            <Typography variant="h5" gutterBottom>
              Ventas del día (Salón: {usuarioActual?.idSalon})
            </Typography>
            {renderTablaVentas(ventasDelDiaSalon)}
          </Box>
        )}
        {activeTab === 1 && (
          <Box>
            <Typography variant="h5" gutterBottom>
              Ventas del día (Usuario Actual: {usuarioActual?.firstName} {usuarioActual?.lastName})
            </Typography>
            {renderTablaVentas(ventasDelDiaUsuario)}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ArqueoDiario;
