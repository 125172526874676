import React, { useState, useContext, useEffect } from 'react';
import { Container, Grid, Snackbar, Alert } from '@mui/material';

import { FormComponentsProvider } from '../../../services/ThemeProvider';
import InputTable from '../../../services/InputTable';
import DataTable from '../../../services/DataTable';
import InventoryContext from '../../../contexts/InventoryContext';
import { ClientAttributesInterface } from '../../../interfaces/models';

const Clientes: React.FC = () => {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error('Clientes debe usarse dentro de un InventoryProvider');
  }

  const { tableData, deleteItem, addItem, updateItem } = context;

  const [nombre, setNombre] = useState<string>('');
  const [celular, setCelular] = useState<string>('');
  const [fechaNacimiento, setFechaNacimiento] = useState<string>(''); // Maneja la fecha como string
  const [clientes, setClientes] = useState<ClientAttributesInterface[]>([]);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: "success" | "error" }>({ open: false, message: '', severity: 'success' });

  const showSnackbar = (message: string, severity: "success" | "error") => {
    setSnackbar({ open: true, message, severity });
  };
  
  const fetchClientes = () => {
    const response = tableData.clients;
  
    if (Array.isArray(response)) {
      const clientesMapeados = response.map((client: ClientAttributesInterface) => ({
        idClient: client.idClient || 0,
        nombre: client.nombre || '',
        celular: client.celular || '',
        fechaNacimiento: client.fechaNacimiento ? new Date(client.fechaNacimiento) : null, // Mantén como Date | null
      }));
      setClientes(clientesMapeados); // Ahora es compatible con ClientAttributesInterface
    } else {
      console.warn("No hay datos de clientes disponibles o el formato de los datos es incorrecto.");
      setClientes([]);
    }
  };
  
  
  
  
  const handleAddClient = async () => {
    if (!nombre || !celular) {
      showSnackbar("El nombre y el celular son obligatorios.", "error");
      return;
    }
  
    const newClient: ClientAttributesInterface = {
      nombre,
      celular,
      fechaNacimiento: fechaNacimiento ? new Date(fechaNacimiento) : null, // Convierte string a Date o null
    };
  
    const response = await addItem('clients', newClient);
    if (response.status) {
      setNombre('');
      setCelular('');
      setFechaNacimiento(''); // Restablece como cadena vacía
      fetchClientes();
      showSnackbar("Cliente agregado exitosamente.", "success");
    } else {
      showSnackbar(`Error al agregar el cliente: ${response.answer}`, "error");
    }
  };
  
  

  const handleDeleteClient = async (clientId: number) => {
    const response = await deleteItem('clients', clientId);
    if (response.status) {
      fetchClientes();
      showSnackbar("Cliente eliminado exitosamente.", "success");
    } else {
      showSnackbar(`Error al eliminar el cliente: ${response.answer}`, "error");
    }
  };

  useEffect(() => {
    fetchClientes();
  }, [tableData]);

  return (
    <FormComponentsProvider>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
          <InputTable
            title="Datos de Cliente"
            rows={[
              [
                { type: 'text', label: 'Nombre', value: nombre, setValue: setNombre, capitalize: true, borderColor: 'white' },
              ],
              [
                { type: 'number', label: 'Celular', value: celular, setValue: setCelular, adornment: '', borderColor: 'white' },
              ],
              [
                {
                  type: 'date',
                  label: 'Fecha de Nacimiento',
                  value: fechaNacimiento, // El estado maneja cadenas
                  setValue: setFechaNacimiento, // Actualiza como string
                  borderColor: 'white',
                },
              ],
            ]}
            onButtonClick={handleAddClient}
            buttonText="Agregar Cliente"
          />

          </Grid>

          <Grid item xs={12} sm={6}>
            <DataTable
              title="Lista de Clientes"
              columns={[
                { field: 'nombre', headerName: 'Nombre' },
                { field: 'celular', headerName: 'Celular' },
                    {
      field: 'fechaNacimiento',
      headerName: 'Fecha de Nacimiento',
      renderCell: (row) =>
        row.fechaNacimiento
          ? new Date(row.fechaNacimiento).toISOString().split('T')[0] // Convertir Date a string
          : '', // Mostrar vacío si es null
    },
              ]}
              rows={clientes}
              filterField="nombre"
              maxHeight="300px"
              selectableRow={true}
              onRowSelect={(row) => console.log('Fila seleccionada:', row)}
              enableDelete={true}
              onDelete={(row) => handleDeleteClient(row.idClient)}
            />
          </Grid>
        </Grid>

        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} variant="filled">
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </FormComponentsProvider>
  );
};

export default Clientes;
