// Reportes.tsx
import React, { useState, useEffect, useContext } from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Grid, Button } from '@mui/material';

import './Reportes.css';
import axios from 'axios';
import { UserAttributes,
  SaleAttributesInterface,
  AsistenciaAttributes,
  ServiceProductAttributesInterface,
  PersonaDatosMezclados,
  ProductosDatosMezclados,
} from '../../../interfaces/models';
import ComponenteDatosPersonas from './ComponenteDatosPersonas';
import ComponenteDatosProductos from './ComponenteDatosProductos';
import ComponenteDatosServicio from './ComponenteDatosServicio';
import InventoryContext from '../../../contexts/InventoryContext';
import Arqueo from './Arqueo';
import { NavLink } from 'react-router-dom';
import OpcionCard from '../../../general/OpcionCard';
import OpcionesGrid from '../../../general/OpcionesGrid';
import CornerButtons from '../../../utils/CornerButtons';

function agruparPorFecha<T extends { updatedAt?: Date; payment?: boolean; pagado?: boolean }>(items: T[]): { [key: string]: T[] } {
  return items.reduce((acc, item) => {
    // Filtrar items por payment o pagado
    if (item.payment === true || item.pagado === true) {
      let fecha = item.updatedAt;
      if (!(fecha instanceof Date)) {
        fecha = new Date(item.updatedAt!);
      }
      if (fecha instanceof Date && !isNaN(fecha.getTime())) {
        const fechaISO = fecha.toISOString().split('T')[0]; // Convierte la fecha a formato YYYY-MM-DD
        if (!acc[fechaISO]) {
          acc[fechaISO] = [];
        }
        acc[fechaISO].push(item);
      }
    }
    return acc;
  }, {} as { [key: string]: T[] });
}



function mezclarDatosPersonas(
  usuarios: UserAttributes[],
  asistencias: AsistenciaAttributes[],
  ventas: SaleAttributesInterface[],
  productos: ServiceProductAttributesInterface[]
): PersonaDatosMezclados[] {
  const productosMap = productos.reduce((map, producto) => {
    map[producto.idServiceProduct!] = producto;
    return map;
  }, {} as { [key: number]: ServiceProductAttributesInterface });

  return usuarios.map(usuario => {
    const ventasUsuario = ventas.filter(venta => venta.idUser === usuario.idUser);
    const asistenciasUsuario = asistencias.filter(asistencia => asistencia.idUser === usuario.idUser);

    // Filtrar ventas y asistencias por payment o pagado en true
    const ventasFiltradas = ventasUsuario.filter(venta => venta.payment === true);
    const asistenciasFiltradas = asistenciasUsuario.filter(asistencia => asistencia.pagado === true);

    const ventasAgrupadas = agruparPorFecha(ventasFiltradas);
    const asistenciasAgrupadas = agruparPorFecha(asistenciasFiltradas);
    const productosServiciosPorFecha = Object.keys(ventasAgrupadas).reduce((acc, fecha) => {
      acc[fecha] = ventasAgrupadas[fecha].reduce((totals, venta) => {
        const producto = productosMap[venta.idServiceProduct!];
        if (producto) { // Verifica que el producto exista antes de acceder a sus propiedades
          if (producto.type === 'producto') {
            totals.productos += venta.quantity || 0;
          } else if (producto.type === 'servicio') {
            totals.servicios += venta.quantity || 0;
          }
        } else {
          console.warn(`Producto con ID ${venta.idServiceProduct} no encontrado.`);
        }
        return totals;
      }, { productos: 0, servicios: 0 });
      return acc;
    }, {} as { [key: string]: { productos: number, servicios: number } });

    return {
      idUser: usuario.idUser!,
      firstName: usuario.firstName!,
      lastName: usuario.lastName!,
      idCard: usuario.idCard!,
      mobileNumber: usuario.mobileNumber!,
      salario: usuario.salario!,
      diasTrabajar: usuario.diasTrabajar!,
      comision: usuario.comision!,
      asistencias: asistenciasUsuario,
      ventas: ventasUsuario,
      ventasAgrupadas,
      asistenciasAgrupadas,
      productosServiciosPorFecha,
      role: usuario.role!
    };
  });
}






function mezclarDatosProductos(
  productos: ServiceProductAttributesInterface[],
  ventas: SaleAttributesInterface[]
): ProductosDatosMezclados[] {
  return productos.map(producto => {
    const ventasProducto = ventas.filter(venta => venta.idServiceProduct === producto.idServiceProduct);

    const utilidadPorDia: { [key: string]: number } = {};

    ventasProducto.forEach(venta => {
      const fecha = new Date(venta.createdAt!).toISOString().split('T')[0];  // Asegúrate de que la fecha no tiene problemas de zona horaria
      const utilidadPorVenta = (venta.quantity ?? 0) * (producto.salesPrice! - producto.purchasePrice!);
      if (utilidadPorDia[fecha]) {
        utilidadPorDia[fecha] += utilidadPorVenta;
      } else {
        utilidadPorDia[fecha] = utilidadPorVenta;
      }
    });

    const fechas = Object.keys(utilidadPorDia).sort();
    let sumaAcumulativa = 0;
    const utilidadAcumulativa = fechas.map(fecha => {
      sumaAcumulativa += utilidadPorDia[fecha];
      return {
        fecha,
        utilidadAcumulativa: sumaAcumulativa
      };
    });

    return {
      ...producto,
      ventas: ventasProducto,
      utilidadAcumulativa
    };
  });
}


const Reportes: React.FC = () => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('Reportes must be used within an InventoryProvider');
  }

  const { tableData } = context;
  const [datosPersonasGraficos, setDatosPersonasGraficos] = useState<PersonaDatosMezclados[]>([]);
  const [datosProductosGraficos, setDatosProductosGraficos] = useState<ProductosDatosMezclados[]>([]);
  const [vistaSeleccionada, setVistaSeleccionada] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = tableData.usuarios as UserAttributes[];
        const asistencias = tableData.asistencias as AsistenciaAttributes[];
        const ventas = tableData.sales as SaleAttributesInterface[];
        const productos = tableData.serviceProducts as ServiceProductAttributesInterface[];

        const nuevoResumenPersonas = mezclarDatosPersonas(users, asistencias, ventas, productos);
        const nuevoResumenProductos = mezclarDatosProductos(productos, ventas);

        setDatosPersonasGraficos(nuevoResumenPersonas);
        setDatosProductosGraficos(nuevoResumenProductos);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [tableData]);

  const handleSeleccionarVista = (vista: string) => setVistaSeleccionada(vista);
  const handleVolverASeleccion = () => setVistaSeleccionada(null);

  const opciones = [
    { titulo: 'Personal', imagen: '/images/opciones/21.png', onClick: () => handleSeleccionarVista('personas') },
    { titulo: 'Productos', imagen: '/images/opciones/22.png', onClick: () => handleSeleccionarVista('productos') },
    { titulo: 'Servicios', imagen: '/images/opciones/23.png', onClick: () => handleSeleccionarVista('servicios') },
    { 
      titulo: 'Arqueo', 
      imagen: '/images/opciones/27_arqueo.png', 
      onClick: () => handleSeleccionarVista('arqueo'), 
      vistaAbajo: true // Esta opción irá justo debajo del resto
    },
  
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {vistaSeleccionada === null ? (
        // Vista de selección inicial usando OpcionesGrid
<>
  <OpcionesGrid opciones={opciones} />
  {/* CornerButtons en la parte inferior */}
  <CornerButtons
    leftButton={{
      to: '/salones',
      icon: '/images/iconos/flecha_atras_negro.png',
      altText: 'Atrás',
    }}
    rightButton={{
      to: '/salones',
      icon: '/images/iconos/home_back.png',
      altText: 'Inicio',
    }}
  />
</>

      ) : (
        // Vista del componente seleccionado
        <Box sx={{ padding: '0 16px', flexGrow: 1, width: '100%', maxWidth: '80vw' }}>

          {vistaSeleccionada === 'personas' && <ComponenteDatosPersonas datos={datosPersonasGraficos} />}
          {vistaSeleccionada === 'productos' && (
            <ComponenteDatosProductos datos={datosProductosGraficos.filter((p) => p.type === 'producto')} />
          )}  
          {vistaSeleccionada === 'servicios' && (
            <ComponenteDatosServicio datos={datosProductosGraficos.filter((p) => p.type === 'servicio')} />
          )}
          {vistaSeleccionada === 'arqueo' && (
            <Arqueo datosPersonas={datosPersonasGraficos} datosProductos={datosProductosGraficos} />
          )}
          {/* Botón de regresar a la vista de selección desde una vista específica */}

            <CornerButtons
              leftButton={{
                
                onClick: handleVolverASeleccion, // Ruta para el botón izquierdo
                icon: '/images/iconos/flecha_atras_negro.png', // Ícono del botón izquierdo
                altText: 'Back', // Texto alternativo para el botón izquierdo
              }}
              rightButton={{
                to: '/salones', // Ruta para el botón derecho
                icon: '/images/iconos/home_back.png', // Ícono del botón derecho
                altText: 'Inicio', // Texto alternativo para el botón derecho
              }}
/>
        </Box>
      )}



    </Box>
  );
};

export default Reportes;
