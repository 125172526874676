import React from 'react';
import { NavLink } from 'react-router-dom';
import './CornerButtons.css';

interface ButtonProps {
  to?: string; // Ruta del botón, opcional si se usa una función
  onClick?: () => void; // Función que se ejecutará al hacer clic
  icon: string; // URL o ruta del ícono
  altText: string; // Texto alternativo para el ícono
}

interface CornerButtonsProps {
  leftButton: ButtonProps;
  rightButton: ButtonProps;
}

const CornerButtons: React.FC<CornerButtonsProps> = ({ leftButton, rightButton }) => {
  return (
    <div className="corner-buttons">
      {/* Botón de la esquina inferior izquierda */}
      {leftButton.to ? (
        <NavLink to={leftButton.to} className="corner-button">
          <img src={leftButton.icon} alt={leftButton.altText} className="corner-icon" />
        </NavLink>
      ) : (
        <button onClick={leftButton.onClick} className="corner-button">
          <img src={leftButton.icon} alt={leftButton.altText} className="corner-icon" />
        </button>
      )}
      {/* Botón de la esquina inferior derecha */}
      {rightButton.to ? (
        <NavLink
          to={rightButton.to}
          className="corner-button"
          onClick={(e) => {
            if (rightButton.onClick) {
              e.preventDefault(); // Previene la navegación automática
              rightButton.onClick(); // Ejecuta la lógica de logout
            }
          }}
        >
          <img src={rightButton.icon} alt={rightButton.altText} className="corner-icon" />
        </NavLink>
      ) : (
        <button onClick={rightButton.onClick} className="corner-button">
          <img src={rightButton.icon} alt={rightButton.altText} className="corner-icon" />
        </button>
      )}

    </div>
  );
};

export default CornerButtons;
